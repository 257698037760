<template>
  <div>
    <div class="block-section">
      <div class="row">
        <div class="form-group col-md-4">
          <datepicker
            v-model="fromDate"
            value-type="format"
            input-class="form-control"
            placeholder="From"
            type="datetime"
          ></datepicker>
        </div>
        <div class="form-group col-md-4">
          <datepicker
            v-model="toDate"
            value-type="format"
            input-class="form-control"
            placeholder="To"
            type="datetime"
          ></datepicker>
        </div>
        <div class="form-group col-md-4">
          <button type="button" class="btn btn-primary" @click="handleSearch">
            <i class="fa fa-search"></i> Search
          </button>
        </div>
      </div>
    </div>
    <div class="table-responsive">
      <table
        class="table table-vcenter table-bordered table-hover table-striped table-condensed"
        v-if="reports && reports.length > 0"
      >
        <thead>
          <tr>
            <th>ID</th>
            <th>Action</th>
            <th>Currency</th>
            <th>Amount</th>
            <th>Fee</th>
            <th>Rate</th>
            <th>Comment</th>
            <th>Time</th>
            <th class="text-center">Action</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="report in reports" :key="report.id">
            <td>{{ report.id }}</td>
            <td>{{ report.Action }}</td>
            <td>{{ report.Currency }}</td>
            <td>{{ report.Amount }}</td>
            <td>{{ report.Fee }}</td>
            <td>{{ report.Rate }}</td>
            <th>{{ report.comment }}</th>
            <td>{{ report.Time }}</td>
            <td class="text-center">
              <a
                v-if="report.url_hash_withdraw"
                class="btn btn-warning btn-sm"
                :href="report.url_hash_withdraw"
                target="_blank"
              >
                Go To Transaction
              </a>
            </td>
          </tr>
        </tbody>
        <tr v-if="totalPage > 1">
          <td colspan="3" class="text-right">
            <paginate
              v-model="page"
              :page-count="totalPage"
              :click-handler="handlePagination"
              :prev-text="'Prev'"
              :next-text="'Next'"
              :container-class="'pagination'"
              :page-class="'page-item'"
            ></paginate>
          </td>
        </tr>
      </table>

      <div v-else class="block-section">No transaction detail</div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "TransactionDetailListItem",
  data: () => ({
    page: 1,
    // totalPage: 1,
    fromDate: null,
    toDate: null
  }),
  created() {
    this.fetchReport();
  },
  computed: {
    ...mapGetters({
      reports: "wallet/History",
      totalPage: "wallet/TotalPages"
    })
  },
  methods: {
    async fetchReport() {
      this.$store.dispatch("wallet/req_getHistory", {
        page: this.page,
        from: this.fromDate,
        to: this.toDate
      });
    },
    handlePagination() {
      this.fetchReport();
    },
    handleSearch() {
      this.fetchReport();
    }
  },
  mounted() {}
};
</script>
<style>
.table thead > tr > th {
  font-size: 14px;
  font-weight: 600;
}
</style>
