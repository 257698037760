<template>
  <base-layout>
    <div class="block full">
      <div class="block-title">
        <h2>Transaction Detail</h2>
      </div>

      <transaction-detail-list></transaction-detail-list>
    </div>
  </base-layout>
</template>

<script>
import TransactionDetailList from "@/components/transactionDetail/ListItem";
import BaseLayout from "./layouts/Base";

export default {
  name: "WinLossReport",
  components: {
    BaseLayout,
    TransactionDetailList
  }
};
</script>
